import React from 'react';

import Dashboard from '../Dashboard';
import EncounterComponent from './EncounterComponent';


const Encounter = () => {
  return (
    <Dashboard>
      <EncounterComponent />
    </Dashboard>
  );
};

export default Encounter;
