import React from 'react';

import Dashboard from '../Dashboard';
import GalleryComponent from './GalleryComponent';

const LikedYou = () => {
  console.log('ocmign her');
  return (
    <Dashboard>
      <GalleryComponent />
    </Dashboard>
  );
};

export default LikedYou;
